.inputField{
 height: 30px !important;
}

.compose_mail_content{
  height: 200px;
  overflow-y: scroll;
}
.compose_mail_content::-webkit-scrollbar {
  width: 0px;
}
.compose_mail_head{
  padding: 0 30px ;
  width: 100%;
  height: 79px;
  background-color: #0061EA;
  border-radius: 12px 12px 0 0;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.compose_mail_close{
  position: absolute;
  right: 0;
}
.compose_mail_head >p{
  margin: 0;
  color: white;
  font-weight: 600;
}
.compose_mail_head >button{
  width: 99px !important;
  height: 40px !important;
  border-radius: 10px !important;
  color: #0061EA !important;
  background-color: white !important;
  border: none !important;
  outline: none !important;

}
.MuiSelect-select{
  border-bottom: 12px solid blueviolet !important;
}
.compose_border_color{
  padding: 10px !important;
}
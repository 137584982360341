.my-field-two .MuiInputBase-input.MuiOutlinedInput-input {
  padding-bottom: 20px !important;
  color: rgba(0, 0, 0, 0.9) !important;
}
.my-field-two .MuiInputBase-root.MuiOutlinedInput-root{
    padding: 0 25px !important;
}
.my-field-two
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.2);
}
.my-field-two fieldset {
  border: none;
  border-radius: 0;
}
.my-field-two svg{
    cursor: pointer !important;
}

/* Main container */
.App {
  display: flex;
  height: 100vh;
}

/* Sidebar styles */


.toggle-button {
  position: absolute;
  top: 10px;
  right: -50px;
  background-color: #3498db;
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
}

.menu-items ul {
  list-style: none;
  padding: 0;
}

.menu-items ul li {
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

/* Hide menu items when the sidebar is closed */
.sidebar.closed .menu-items {
  display: none;
}

/* Content styles */
.content {
  flex-grow: 1;
  background-color: aqua !important;
}


:root {
  /* --primary-color: #22b378 !important; */
  --button-color:#0061EA !important;
  --primary-color: #734f96  !important;
  --secondary-color: rgb(255, 255, 255)  !important;
  --primary-color-light: #E4D4EB  !important;
  /* --primary-color: rgb(255, 255, 255) !important;
  --secondary-color:   rgba(0,85,80,1) !important; */
  --lite-white-color:   #ffffffc2 !important;
  --lite-green-color:rgba(0,85,80,1) !important;
}

.my-field .MuiInputBase-input.MuiOutlinedInput-input{
  padding: 10px !important;
  color: rgba(0,0,0,0.9) !important;
}
.my-field .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: rgba(0,0,0,0.9) !important;
}
.my-field fieldset{
  border: none;
  border-bottom: 1px solid rgba(0,0,0,0.9);
  border-radius: 0;
}

.d-flex{
  display: flex;
  align-items: center;
}
.d-flex-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.d-flex-around{
  display: flex;
  align-items: center;
  justify-content: space-around;
}

audio::-webkit-media-controls {
  background-color: rgb(255, 255, 255); /* Background color of controls */
  border-radius: 5px;
}

audio::-webkit-media-controls-panel {
  background-color: rgb(255, 255, 255); /* Panel background */
  border: none !important;
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-pause-button {
  /* background-color: var(--primary-color);  */
  border-radius: 5px;
  color: #fff !important;
}

audio::-webkit-media-controls-volume-slider,
audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display {
  /* color: #fff; */
}

.emoji-picker-react .emoji-search {
  display: none;
}
.css-1oqqzyl-MuiContainer-root{
  padding: 0 !important;
}
/* ********* start ********* */
.scale-down-center {
	-webkit-animation: scale-down-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-down-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes scale-down-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-down-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}




/* ********* end ********* */
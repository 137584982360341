/* header Part Css Starts */

.user_header {
  display: flex;
  align-items: center;
  padding: 18px 20px !important;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
  gap: 8px;
  position: relative;
  z-index: 1;
  /* background-color: #fff !important; */
}

.menubar_icon {
  font-size: 26px !important;
  cursor: pointer;
}

.reminder_title {
  /* box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2) !important; */
  border: none !important;
  /* padding: 18px 33px !important; */
  font-size: 19px !important;
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
}

.reminder_title svg {
  font-size: 20px !important;
}

/* header Part Css Ends */

/* SubSideBar Part Starts  */

.iconsPart {
  display: flex;
  column-gap: 5px;
  align-items: center;
}

.sidebar {
  height: 100vh;
  background-color: white;
  color: white;
  position: relative;
  transition: width 0.3s ease;
  /* overflow: hidden;  */
}

.small_navbar_select {
  overflow-x: hidden;
  display: flex;
  padding: 44px 21px 31px 16px;
  border-bottom: 1px solid #E5E5E5;
}

.small_navbar_select>div {
  overflow-x: hidden;
}

.small_navbar_details {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;

}

.small_navbar {
  background-color: #F1F7FF;
}

.checkbox {
  transform: scale(0.7);
  margin-left: 12px !important;
  padding: 0 !important;
}

/* Open Sidebar */
.sidebar.open {
  width: 346px;
  /* Full width when open */
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  position: relative;
}

.email_subdetails {
  color: #707991;
  display: flex;

  font-size: 12px;
}
.email_subdetails_sender{
  color: #707991;
  font-size: 12px;
  text-align: end;
}
.toggleButton {
  position: absolute !important;
  top: 10px;
  border: none !important;
  color: white !important;
  z-index: 22 !important;
  font-size: 22px !important;
  cursor: pointer !important;
  min-width: unset !important;


}

.toggleButton {
  position: absolute !important;
  top: 10px;
  border: none !important;
  color: white !important;
  z-index: 22 !important;
  font-size: 22px !important;
  cursor: pointer !important;
  min-width: unset !important;


}

.icon_close {
  position: relative;
  z-index: -1;
}

.toggleButton>i {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.sidebar.closed .toggleButton {
  right: -20px;
  top: 45px;
  z-index: -3 !important;
}

.icon {
  border-radius: 50%;
  color: tomato;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  padding: 2px !important;
}

.sidebar.open .toggleButton {
  right: -20px;
  top: 45px;
}

.mail_heading {
  display: flex;
  padding: 44px 21px 31px 16px;
  justify-content: space-between;

}

.menuItems {
  gap: 25px !important;
  height: 100%;
  overflow: auto !important;
}
.menuItems_small{
  padding: 5px 16px 0px 20px !important;
}
.menuItems::-webkit-scrollbar {
  display: none !important;
}

.menuItems ul {
  list-style: none;
  padding: 0;
}

.menuItems a {
  text-decoration: none !important;
  color: var(--primary-color) !important;
}

.SelectedAvatar {
  width: 48px !important;
  height: 48px !important;
}

.unSelectedAvatar {
  width: 48px !important;
  height: 48px !important;
}

.MainContainer {
  background-color: #E4E4E4;
  display: flex;
  height: 100vh;
}

.sidebar.closed .menuItems {
  display: none;
}

/* Closed Sidebar */
.sidebar.closed {
  width: 0px;
  /* Minimal width when closed, just enough for icons or a small indicator */
}

.iconsPart button {
  min-width: 0 !important;
}

.sidebar_logo {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 5px !important;
  padding: 14px 21px 12px 16px !important;
  height: 74px;
}

.email_all_category>li {
  border-top: 1px solid #E8E8E8;
}

.active_category {
  background-color: #D6E7FE;
}

.sidebar_logo:hover {
  background-color: #F1F7FF;
  border: 1px solid #D6E7FE;
}
.sidebar_logo_active{
  background-color: #F1F7FF;
  border: 1px solid #D6E7FE;
}
.sidebar_logo:hover .category_details_right {
  background-color: white;
}

.sidebar_logo2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  cursor: pointer;
  border-radius: 5px !important;
  width: 100%;
  /* padding: 10px 20px; */

}

.category_details_right {
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  font-size: 12px;
  color: #707991;
  border-radius: 3px;
  background-color: #F5F5F5;
}

.mail_single_input {
  padding: 10px 40px 0 10px !important;
  bottom: 10px !important;
  width: 100%;
  border: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  outline: none !important;
}

.category_details {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category_details_left {
  display: flex;
  align-items: center;
  gap: 11px;
}

/* #f0f7ff */
.send_icon {
  font-size: 40px;
  position: absolute !important;
  right: 60px !important;
  z-index: 10;
  cursor: pointer;
  bottom: 50%;
  transform: translateY(50%);
  width: 32px;
  height: 32px;

}

.category_details {
  display: flex;
}

.userNameText {
  color: #000 !important;
  font-weight: 600 !important;
}

.userEmailText {
  color: #000 !important;
  font-size: 12px !important;
  width: 175px;
  margin-left: 5px !important;
}

.userEmailText_seen {
  color: #1a18189c !important;
  font-size: 12px !important;
  width: 175px;
  margin-left: 5px !important;

}

.toggleButton2 {
  top: 50px;
}

.toggleIcon {
  border-radius: 50% !important;
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: #F59921;

}

.suggestion_container {
  z-index: 10;
  margin: 10px;
  position: relative !important;
  padding: 10px !important;
  border-radius: 12px !important;

}

.suggestion_content {
  display: flex !important;
  overflow-y: auto;
  gap: 30px;
  margin: 10px;
}

.suggestion_text {
  background-color: white;
  border-radius: 12px;
  ;
  padding: 15px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid #0061EA;
  color: #0061EA;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.EmailHtmlContainer {
  height: 100vh;
  overflow: auto;
}

/* SubsideBarPArtEnds */

.MainContainer {
  display: flex;
  height: 100vh;
}

.content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
}

.emailViewer {
  padding: 20px;
  padding-left: 35px;
  margin: 10px;
  overflow-y: auto !important;
  scrollbar-width: none !important;
  border-radius: 12px !important;
  box-shadow: none !important;
}

.textContent {
  margin-top: 20px;
}

.htmlContent {
  margin-top: 20px;
}

.attachments {
  margin-top: 20px;
}

.attachmentWrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.attachmentPreview {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  width: 120px;
  /* Adjust width */
  transition: box-shadow 0.3s ease;
}

.attachmentPreview:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.attachmentName {
  margin: 5px 0;
}

.downloadButton {
  display: none;
  /* Hide button by default */
  margin-top: 5px;
}

.attachmentPreview:hover .downloadButton {
  display: block;
  /* Show button on hover */
}

.point {
  position: absolute;
  width: 10px;
  height: 10px;
  right: 0;
  border-radius: 50%;
}

.delete_icon {
  color: rgba(245, 21, 21, 0.795);
  margin-right: 10px;
}

.loading {
  position: fixed;
  z-index: 9999;
  top: 0;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(173, 187, 187, 0.356);
  left: 0;
}

.suggestion_container_form {
  width: 300px !important;
  padding: 20px 10px;
  background-color: var(--primary-color-light);
  box-shadow: none !important;

}

.draftMessage {
  margin-top: 10px;
  height: 400px;
  overflow-y: scroll;
  scrollbar-width: none !important;
}

.userAvatar {
  width: 49px !important;
  height: 49px !important;
}

.emailViewer_details_h {
  padding: 11px 13px 18px 35px !important;
  box-shadow: none !important;
  height: 96px !important;
  margin: 10px;
  border-radius: 10px !important;
  box-sizing: border-box !important;
}

.from_heading_detail {
  font-size: 12px !important;
}

.sub_heading_detail {
  margin: 0 !important;
  font-size: 16px !important;
}

.to_heading_detail {
  font-size: 12px !important;

}

.emailHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;

}

.normal_line {
  width: 1px;
  background-color: #CBCBCB;
}

.emailHeader>div>h6 {
  margin-top: 5px;
}

.email_detail_frd_img {
  display: flex;
  gap: 12px;
  margin-bottom: 10px;
  margin-right: 12px;
}
.email_detail_frd_img1{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 56px;
}
.normal_line1{
  height: 17px;
  width: 1px;
  background-color: #CBCBCB;
}
.emailViewer_container{
height: 88%;
overflow-x: scroll;
scrollbar-width: none !important;
}
.sidebar_container {
  min-height: 100vh !important;
  max-height: 100vh !important;
}
.menubar_icon {
  font-size: 26px !important;
  cursor: pointer;
}

.sideBarMainContainer {
  width: 112px !important;
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: center;
}
.user_container {
  background-color:#d9d9d94d ;
  height: 100vh !important;
  display: flex !important;
  flex-direction: column !important;
  position:  relative !important; 
}
.user_header_container {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  padding: 10px 15px !important;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
  /* height: 10% !important; */
  position: relative;
  z-index: 1;
}
.user_header {
  display: flex;
  align-items: center;
  /* padding: 10px 20px !important;
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2); */
  gap: 8px;
  /* height: 10% !important;
    position: relative;
    z-index: 1; */
}
.chat_part {
  height: 90vh;
  margin-top: 20px;
  padding: 0px 15px 60px !important;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-grow: 1 !important;
  
}
.sidebar_icons{
  font-size: 32px ;
  color:#636363 ;
}
.sidebar_icons:hover{
  color: #0061EA;
}
.welcome_part {
  padding: 24px !important;
  overflow-x: hidden;
  flex-grow: 1 !important;
  display: grid !important;
  place-items: center !important;
  position: absolute;
  top: 50% ;
  left: 50%;
  transform: translate(-50%, -50%);
  
}
.welcome_part div {
  width: 300px !important;
  border: 1px double rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
  padding: 16px !important;
}
.welcome_part p {
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 14px !important;
  margin-top: 8px !important;
}
.welcome_part svg {
  font-size: 28px !important;
  color: var(--primary-color) !important;
}
.text_part {
  z-index: 12;
  bottom: 0;
  margin: 0 auto;
}
.msg_text{
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}
.msg_text >div{
  width: 40px;
  height: 40px;
  background-color: #29AB91;
  border-radius: 50%;
 display: flex;
 justify-content: center;
 align-items: center;
}
.msg_text >p{
  width: 100% !important;
  flex: 1;
}
.long_sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 16px;
  margin-top: 0 !important;
}
.short_sidebar {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 10px 0;
  margin-top: 0 !important;
  gap: 50px !important;
}
.sidebar_logo {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 16px;
  cursor: pointer;
  border-radius: 5px !important;
  padding: 12px 16px !important;
}


.menuItems{
  height: 100%;
  overflow: auto !important;
}

.menuItems::-webkit-scrollbar{
  display: none !important;
}

.my_msg {
  text-align: end;
  margin-bottom: 12px;
  position: relative;
}
.my_msg > div {
  display: flex;
  align-items: start;
  justify-content: end;
  gap: 8px;

}
.my_msg p {
  background-color: white !important;

  display: inline-block;
  padding: 8px 8px 15px 8px;
  border-radius: 7px;
  width: auto;
  /* max-width: 300px !important;
    min-width: 300px; */
  text-align: start;
  color: #000 !important;
  position: relative;
}

.ConvoListContainer {
  max-height: calc(100vh - 290px);
  overflow-y: auto;
  padding-right: 10px; /* Add space inside container */
}

.ConvoListContainer::-webkit-scrollbar {
  width: 0px; /* Thin scrollbar width */
}

.ConvoListContainer:hover::-webkit-scrollbar {
  width: 6px; /* Show thin scrollbar on hover */
}

.ConvoListContainer::-webkit-scrollbar-thumb {
  background-color: var(--primary-color-light); /* Light gray scrollbar */
  border-radius: 10px;
  margin-right: 4px; /* Adds space between scrollbar and content */
}

.ConvoListContainer::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.8); /* Light background for track */
}


.res_msg {
  text-align: start;
  margin-bottom: 12px;
  position: relative;
}
.res_msg p {
  background-color: #D6E7FF;
  color: #000;
  /* box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); */
  display: inline-block;
  padding: 12px 4px;
  border-radius: 12px;
  width: auto;
  /* max-width: 600px !important;
    min-width: 300px; */
  text-align: start;
  position: relative;
  /* word-break: break-all; */
  word-wrap: break-word;
  margin: 0 !important;
}

.msg_time {
  position: absolute;
  bottom: 0px;
  right: 6px;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #29AB91;
}
.msg_time svg {
  font-size: 19px;
  color: #29AB91;
}
.msg_time_user {
  position: absolute;
  bottom: 0px;
  right: 6px;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
.logout_btn {
  background-color: var(--button-color) !important;
  color: var(--secondary-color) !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  min-width: 50px !important;
}
.uploaded_name {
  text-decoration: underline;
  display: block;
  cursor: pointer;
}
.uploaded_name:hover {
  text-decoration: none;
}
.emoji_picker {
  position: absolute !important;
  top: -453px !important;
  left: 5px !important;
}
/* .emoji_picker aside div:last-child{
    display: none !important;
} */
.drawer_part {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  height: 100%;
  position: relative;
}
.duration {
  background-color: #3D70B899;
  padding: 4px 11px;
  border-radius: 12px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 13px;
  color: white;
}
.notification {
  font-size: 27px !important;
  /* margin-right: 37px !important; */
  cursor: pointer !important;
}
.dots {
  font-size: 21px !important;
  cursor: pointer;
}
.pro_icon {
  font-size: 56px;
  color: grey;
}
.create_new {
  cursor: pointer !important;
  text-align: center !important;
  margin-top: 25px !important;
}

.iconsPart {
  display: flex;
  column-gap: 5px;
  align-items: center;
}

.sidebar {
  height: 100%;
  color: white;
  padding: 10px;
  position: relative;
  transition: width 0.3s ease;
  /* overflow: hidden;  */
}

/* Open Sidebar */
.sidebar.open {
  width: 346px; /* Full width when open */
}

.content {
  flex: 1;
  position: relative;
  background-color: #F5F5F5;
  background-image: url("../../assets//images//Rectangle\ 7.png");
}
.toggle_button_icon{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  border-radius: 50%;
}

.toggleButton {
  position: absolute !important;
  top: 16px;
  border: none !important;
  color: #F59921 !important;
  z-index: 22;
  font-size: 22px !important;
  cursor: pointer;
  min-width: unset !important;

}

.sidebar.closed .toggleButton {
  right: -20px;
  top: 30px;
}

.sidebar.open .toggleButton {
  top: 30px;
  right: -20px;
}

.UserNameText{
  color: var(--primary-color);
}

.SelectedUserNameText{
  display: flex;
  color: #000 !important;
  font-weight: 600 !important;
}

.SelectedAvatar{
  width: 48px !important;
  height: 48px !important;
  background-color: var(--primary-color) !important;
}

.unSelectedAvatar{
  color: var(--primary-color) !important;
  background-color: var(--secondary-color) !important;
}

.menuItems ul {
  list-style: none;
  padding: 0;
}

.menuItems a{
  text-decoration: none !important;
  color: var(--primary-color) !important;
}

.menuItems ul li {
  padding: 5px 0;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.2); */
}

.MainContainer {
  display: flex;
  height: 100vh;
}

.sidebar.closed .menuItems {
  display: none;
}

/* Closed Sidebar */
.sidebar.closed {
  width: 0px; /* Minimal width when closed, just enough for icons or a small indicator */
}

.iconsPart button {
  min-width: 0 !important;
}
.user_lastmessage{
  display: flex;
  justify-content: space-between;
}
.deleteBinIcon{
  display: none !important;
  position: absolute !important;
  right: 0;
  color: #000 !important;
  font-size: 20px !important;
  z-index: 22;
}

.sidebar_logo:hover .deleteBinIcon{
  display: block !important;
}

.createConvoBtn{
  width: 100%;

  text-transform: none !important;
  display: flex;
  column-gap: 3px;
  font-size: 15px;
  justify-content: space-between !important;
  align-content: center;
}
.createConvoBtn >p{
  font-size: 16px;
  margin: 0 !important;
  font-weight: 600 !important;

  color: #0061EA;
}
.createConvoBtn_icon{
font-size: 24px;
color: #0061EA;
margin-right: 10px;
}
.customTextField {
  border: none;
  background-color: #F5F5F5;
  border-radius: 20px !important;/* Optional: Adjust padding as needed */
}
.sidebar_chat_info{
  width: 100%;
  display: flex;
  gap: 4px;
  color: black;
  flex-direction: column;
}
.unread_count{
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: white;
  background-color: #33B296;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px !important;}
  .last_message{
    font-size: 14px;
    color:#707991 ;
  }
  .message_time{
    font-size: 12px;
    color: #707991;
  }
  .slected_user_details{
    top: 0;
    position: sticky;
    width: 100%;
    height: 56px;
    background-color: #FFFFFF;
    border-radius: 12px;
    z-index: 99;
    padding: 7px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .user_detail_left{
    gap: 16px;
    display: flex;
    align-items: center;
  }
  .left_details >p{
    color: black;
    margin: 0 !important;

  }
  .left_details_lastseen{
    font-size: 14px ;
    color: #707991 !important;
  }
  .left_details_user_name{
    font-size: 16px;
    font-weight: 600;
  }
  .user_detail_right{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .user_detail_right >img{
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer !important;
  }

@media only screen and (max-width: 600px) {
  /* .user_header{
        height: 6% !important;
    }
    .chat_part{
        height: 74% !important;
    } */
  /* .text_part{
        position: absolute !important;
        bottom: 0 !important;
        background-color: #fff !important;
    } */
  .user_container {
    height: 93vh !important;
  }
}

@media screen and (max-width: 1360px) {
  .text_part{
  padding: 0 16px !important;
  }
}
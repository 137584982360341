.sidebar_logo {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border-radius: 5px !important;
    padding: 8px !important;
    margin-top: 6px !important;
  }

  .userNameText{
    color: #000 !important;
    font-weight: 600 !important;
  }

  .userEmailText{
    color: #000 !important;
    font-size: 12px !important;
  }
  .menuItems{
    height: 100%;
    overflow: auto !important;
  }

  .menuItems::-webkit-scrollbar{
    display: none !important;
  }

  .menuItems ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }
  
  .menuItems a{
    text-decoration: none !important;
    color: var(--primary-color) !important;
  }
  
  .menuItems ul li {
    padding: 5px 0;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.2); */
  }

  .HeaderText{
    padding: 20px 20px 10px;
  }

  .ModalSubmitBtn{
    text-transform: capitalize !important;
    float: right;
    margin: 20px 25px !important;
    background-color: var(--primary-color) !important;
  }

  .checkboxMenu svg{
    color: var(--primary-color) !important;
  }
.point{
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.notification-pop .MuiPaper-root.MuiDialog-paper {
  width: auto !important;
  /* max-width: 70% !important; */
  min-width: 31% !important;
  /* max-width: 100% !important; */
  /* padding: 24px; */
  position: relative;
}
.popup-header {
  padding: 15px 24px 0px 24px !important;
}
.popup-header svg {
  font-size: 35px !important;
  cursor: pointer !important;
}
.notification-box {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
  padding: 20px;
  padding-bottom: 30px;
  display: flex;
  align-items: start;
  gap: 20px;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
}
.notification-active {
  background-color: #3d6c6d38 !important;
}
.notification-time {
  position: absolute;
  bottom: 4px;
  right: 35px;
  color: black;
  /* font-weight: 600 !important; */
}
.hover-onclose {
  position: absolute;
  top: 13px;
  right: 16px;
  font-size: 21px !important;
  color: #db5050 !important;
  visibility: hidden;
}
.notification-box:hover .hover-onclose {
  visibility: visible;
  transition: visibility 0.2s;
}

.create-pop .MuiPaper-root.MuiDialog-paper {
  width: auto !important;
  /* max-width: 70% !important; */
  min-width: 31% !important;
  /* max-width: 100% !important; */
  /* padding: 24px; */
  position: relative;
}
.create-pop li {
  margin: 8px 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.7);
}
.activeList {
  color: rgba(0, 0, 0, 1) !important;
  font-weight: 500;
  background-color: var(--primary-color-light)  !important;
}
.create-pop .btn-one {
  background-color: var(--primary-color-light) !important;
  text-transform: capitalize !important;
  color: var(--primary-color) !important;
}

.category-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  column-gap: 20px;
  padding: 20px;
}

.nameCard {
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
  padding: 6px !important;
  border-radius: 8px !important;
}

.category-icon {
  height: 40px;
}

.create-btn {
  display: flex;
}

.iconContainer {
  margin-bottom: "15px";
}

.icon {
  width: "40px";
  height: "40px";
  color: "#32CD32";
}
.title {
  font-size: "24px";
  margin: "0";
  font-weight: "bold";
}
.message {
  font-size: "16px";
  color: "#666";
}

.success-popup {
  display: flex !important;
  position: relative;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 40px 20px;
}

.success-popup .closeIcon {
  position: absolute;
  font-size: 28px !important;
  cursor: pointer !important;
  right: 10px;
  top: 10px;
}

.convo-field .MuiInputBase-input.MuiOutlinedInput-input{
  padding: 15px !important;
  color: rgba(0,0,0,0.9) !important;
}
.convo-field .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: var(--primary-color) !important;
}
.convo-field fieldset{
  border: 1px solid rgba(0,0,0,0.2);
}
.category-name{
  font-size: 14px ;
  margin: 0;
  color: #714d9b;
  font-weight: bold;
}
.category-name >span{
  font-weight: normal !important;
}
.popup-header-3{
  padding: 16px !important;
}
@media only screen and (max-width: 600px) {
    .nameCard p{
        font-size: 16px!important;
    }
    .popup-header svg {
      font-size: 31px !important;
      cursor: pointer !important;
    }
    .category-list {
      grid-template-columns: repeat(1, 1fr);
    }
    .create-pop .MuiPaper-root.MuiDialog-paper {
      width: auto !important;
      /* max-width: 70% !important; */
      min-width: 91% !important;
      /* max-width: 100% !important; */
      /* padding: 24px; */
      position: relative;
    }
}
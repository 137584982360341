.aicomponent {
  width: 350px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 370px !important;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  position: relative;
}

.closebutton {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.chatContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  overflow-x: scroll;
  scrollbar-width: none;
}

.myMessage {
  align-self: flex-end;
  background-color: #d1e7ff;
  padding: 8px 12px;
  border-radius: 10px;
  margin: 4px 0;
  max-width: 70%;
  text-align: right;
}

.aiMessage {
  align-self: flex-start;
  background-color: #f1f0f0;
  padding: 8px 12px;
  border-radius: 10px;
  margin: 4px 0;
  max-width: 70%;
  text-align: left;
}

.inputContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.input {
  flex-grow: 1;
  height: 100px;
  padding: 10px 30px 10px 10px;
  border-radius: 4px;
  border: none;
  outline: none;
}

.sendButton {
  border: none;
  cursor: pointer;
  position: absolute;
  width: 30px;
  height: 30px;
  right: 10px;
}

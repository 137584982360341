.confirm-pop .MuiPaper-root.MuiDialog-paper {
    width: auto !important;
    min-width: 30% !important;
    padding: 30px 16px;
    position: relative;
  }
  .outlined-btn{
    border: 1px solid var(--primary-color) !important;
    color: var(--primary-color) !important;
    text-transform: capitalize !important;
    padding: 6px 30px !important;
  }
  .contained-btn{
    border: 1px solid var(--primary-color) !important;
    color: var(--secondary-color) !important;
    background-color: var(--primary-color) !important;
    text-transform: capitalize !important;
    padding: 6px 30px !important;
  }
.reminder_title {
  /* box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2) !important; */
  border: none !important;
  /* padding: 18px 33px !important; */
  font-size: 19px !important;
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
}
.reminder_title svg {
  font-size: 24px !important;
}

.reminder_part_parent {
  height: calc(100vh - 65px);
  overflow-y: auto;
}

.reminder_part {
  /* background-color: #D9D9D94D !important; */
  padding: 20px 40px 40px 40px !important;
  /* height: 100vh !important; */
  /* overflow-y: scroll;
    overflow-x: hidden; */
}
.single_reminder {
  background: white !important;
  padding: 12px 26px !important;
  margin-bottom: 22px !important;
  position: relative !important;
}
.single_reminder p {
  margin-bottom: 7px !important;
}
.single_reminder span {
  color: rgba(0, 0, 0, 0.6) !important;
  margin-top: 5px !important;
}
.single_reminder small {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
  margin-top: 5px;
  color: #337ec1;
  cursor: pointer;
}
.single_reminder small svg {
  font-size: 17px !important;
}
.back_arrow {
  display: flex !important;
  align-items: center !important;
  gap: 7px !important;
  margin-bottom: 30px !important;
  cursor: pointer !important;
  user-select: none !important;
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.8) !important;
  text-transform: capitalize !important;
}
.menubar_icon {
  font-size: 26px !important;
  cursor: pointer;
}
.user_header {
  display: flex;
  align-items: center;
  padding: 18px 20px !important;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
  gap: 8px;
  position: relative;
  z-index: 1;
  /* background-color: #fff !important; */
}
.no_record {
  display: grid !important;
  place-items: center !important;
}
.no_record img {
  width: 381px !important;
  height: 345px !important;
  filter: grayscale();
}


.deleteBinIcon{
  display: none !important;
  position: absolute !important;
  right: 0;
  color: #000 !important;
  font-size: 20px !important;
  z-index: 22;
  top: 25px;
}

.single_reminder:hover .deleteBinIcon{
  display: block !important;
}

@media only screen and (max-width: 600px) {
  .reminder_part {
    padding: 24px !important;
  }
  .no_record img {
    width: 353px !important;
    height: 315px !important;
    filter: grayscale();
  }
}
